var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.$store.state.examine.examinePopoverVisible,
        "custom-class": "examinePopover",
        "before-close": _vm.beforeClose,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: ""
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.examine,
            "examinePopoverVisible",
            $event
          )
        }
      }
    },
    [
      _vm.type == 1 && _vm.postDetails
        ? _c("div", { staticClass: "examine-wrap post-wrap" }, [
            _vm.documentType == 1
              ? _c("div", { staticClass: "video-post" }, [
                  _c("div", { staticClass: "article-top" }, [
                    _c(
                      "div",
                      {
                        staticClass: "user-img",
                        staticStyle: {
                          "border-radius": "50%",
                          overflow: "hidden"
                        }
                      },
                      [
                        _vm.postDetails.profilePhoto
                          ? _c("el-image", {
                              attrs: {
                                src: _vm.postDetails.profilePhoto,
                                "preview-src-list": [
                                  _vm.postDetails.profilePhoto
                                ]
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "user-content ellipsis-2" },
                      [
                        _vm.postDetails.vipStatus
                          ? _c(
                              "span",
                              { staticClass: "xvipName" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "vipDisplayName",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.goPersonalCenter(
                                          _vm.postDetails.providerId
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.postDetails.name))]
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.$t("paidMember"),
                                      placement: "bottom",
                                      "popper-class": "me-tooltip-effect"
                                    }
                                  },
                                  [
                                    _c("el-image", {
                                      staticClass: "vipIcon",
                                      attrs: {
                                        src: require("@/assets/images/pay/masonry.png"),
                                        fit: "fill"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.handleClickVip(
                                            _vm.postDetails.providerId
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : [
                              _c(
                                "div",
                                {
                                  staticClass: "user-name",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.goPersonalCenter(
                                        _vm.postDetails.providerId
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.postDetails.name) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                        _c("div", { staticClass: "user-text" }, [
                          _vm._v(_vm._s(_vm.postDetails.publishTime))
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "toTop",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toTop.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../../assets/images/toTop.png")
                              }
                            })
                          ]
                        )
                      ],
                      2
                    )
                  ]),
                  _c("div", { staticClass: "article-title" }, [
                    _vm._v(_vm._s(_vm.postDetails.iCanDo))
                  ]),
                  _vm.postDetails.richDetail
                    ? _c("div", {
                        staticClass: "article-content",
                        domProps: {
                          innerHTML: _vm._s(_vm.postDetails.richDetail)
                        }
                      })
                    : _vm._e(),
                  !_vm.postDetails.richDetail &&
                  _vm.postDetails.detail &&
                  JSON.parse(_vm.postDetails.detail)[0]
                    ? _c("div", {
                        staticClass: "article-content",
                        domProps: {
                          innerHTML: _vm._s(
                            JSON.parse(_vm.postDetails.detail)[0].content
                          )
                        }
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "article-video" }, [
                    _c("video", {
                      staticStyle: { "max-width": "100%", height: "100%" },
                      attrs: { src: _vm.vodie, id: "examVideo", controls: "" }
                    })
                  ]),
                  _vm.postDetails.tags
                    ? _c(
                        "div",
                        { staticClass: "tagsBox" },
                        _vm._l(_vm.postDetails.tags.split(","), function(
                          tag,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "labelItem" },
                            [
                              _c("span", {
                                staticClass: "iconfont_Me icon-tag2 labelIcon"
                              }),
                              _c("span", { staticClass: "labelContent" }, [
                                _vm._v(_vm._s(tag))
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.documentType == 3
              ? _c("div", { staticClass: "pic-post" }, [
                  _c("div", { staticClass: "article-top" }, [
                    _c(
                      "div",
                      {
                        staticClass: "user-img",
                        staticStyle: {
                          "border-radius": "50%",
                          overflow: "hidden"
                        }
                      },
                      [
                        _vm.postDetails.profilePhoto
                          ? _c("el-image", {
                              attrs: {
                                src: _vm.postDetails.profilePhoto,
                                "preview-src-list": [
                                  _vm.postDetails.profilePhoto
                                ]
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "user-content ellipsis-2" },
                      [
                        _vm.postDetails.vipStatus
                          ? _c(
                              "span",
                              { staticClass: "xvipName" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "vipDisplayName",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.goPersonalCenter(
                                          _vm.postDetails.providerId
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.postDetails.name))]
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.$t("paidMember"),
                                      placement: "bottom",
                                      "popper-class": "me-tooltip-effect"
                                    }
                                  },
                                  [
                                    _c("el-image", {
                                      staticClass: "vipIcon",
                                      attrs: {
                                        src: require("@/assets/images/pay/masonry.png"),
                                        fit: "fill"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.handleClickVip(
                                            _vm.postDetails.providerId
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : [
                              _c(
                                "div",
                                {
                                  staticClass: "user-name",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.goPersonalCenter(
                                        _vm.postDetails.providerId
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.postDetails.name) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                        _c("div", { staticClass: "user-text" }, [
                          _vm._v(_vm._s(_vm.postDetails.publishTime))
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "toTop",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toTop.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../../assets/images/toTop.png")
                              }
                            })
                          ]
                        )
                      ],
                      2
                    )
                  ]),
                  _c("div", { staticClass: "article-title" }, [
                    _vm._v(_vm._s(_vm.postDetails.iCanDo))
                  ]),
                  _vm.postDetails.richDetail
                    ? _c("div", {
                        staticClass: "article-content",
                        domProps: {
                          innerHTML: _vm._s(_vm.postDetails.richDetail)
                        }
                      })
                    : _vm._e(),
                  _vm.postDetails.detail &&
                  _vm.postDetails.detail != "[]" &&
                  !_vm.postDetails.richDetail
                    ? _c(
                        "div",
                        { staticClass: "article-content" },
                        _vm._l(JSON.parse(_vm.postDetails.detail), function(
                          item,
                          dIndx
                        ) {
                          return _c("div", { key: dIndx }, [
                            item.key == "text"
                              ? _c("div", { staticClass: "cont" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.content) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e(),
                            item.key == "image"
                              ? _c("div", { staticClass: "cont" }, [
                                  _c("img", {
                                    staticStyle: {
                                      width: "auto",
                                      height: "77px"
                                    },
                                    attrs: { src: item.content, alt: "" }
                                  })
                                ])
                              : _vm._e(),
                            item.key == "web"
                              ? _c("div", { staticClass: "cont" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "_a",
                                      attrs: {
                                        href: item.content.url,
                                        target: "_black"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "webImag" }, [
                                        _c("img", {
                                          attrs: {
                                            src: item.content.image,
                                            alt: ""
                                          }
                                        })
                                      ]),
                                      _c("div", { staticClass: "webInfo" }, [
                                        _c("div", { staticClass: "webTitle" }, [
                                          _vm._v(_vm._s(item.content.title))
                                        ]),
                                        _c("div", { staticClass: "webCont" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.content.content) +
                                              "\n                "
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "article-img" },
                    _vm._l(_vm.postDetails.attachments, function(item, indx) {
                      return _c("el-image", {
                        key: indx,
                        attrs: {
                          src: item.path,
                          "preview-src-list": [item.path]
                        }
                      })
                    }),
                    1
                  ),
                  _vm.postDetails.tags
                    ? _c(
                        "div",
                        { staticClass: "tagsBox" },
                        _vm._l(_vm.postDetails.tags.split(","), function(
                          tag,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "labelItem" },
                            [
                              _c("span", {
                                staticClass: "iconfont_Me icon-tag2 labelIcon"
                              }),
                              _c("span", { staticClass: "labelContent" }, [
                                _vm._v(_vm._s(tag))
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.documentType == 4
              ? _c(
                  "div",
                  { staticClass: "file-post" },
                  [
                    _c("div", { staticClass: "article-top" }, [
                      _c(
                        "div",
                        {
                          staticClass: "user-img",
                          staticStyle: {
                            "border-radius": "50%",
                            overflow: "hidden"
                          }
                        },
                        [
                          _vm.postDetails.profilePhoto
                            ? _c("el-image", {
                                attrs: {
                                  src: _vm.postDetails.profilePhoto,
                                  "preview-src-list": [
                                    _vm.postDetails.profilePhoto
                                  ]
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticClass: "user-content" }, [
                        _c(
                          "div",
                          {
                            staticClass: "user-name",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.goPersonalCenter(
                                  _vm.postDetails.providerId
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.postDetails.name) +
                                "\n          "
                            )
                          ]
                        ),
                        _c("div", { staticClass: "user-text" }, [
                          _vm._v(_vm._s(_vm.postDetails.publishTime))
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "toTop",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toTop.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../../assets/images/toTop.png")
                              }
                            })
                          ]
                        )
                      ])
                    ]),
                    _vm.postDetails.iCanDo
                      ? _c("h2", [_vm._v(_vm._s(_vm.postDetails.iCanDo))])
                      : _vm._e(),
                    _vm.postDetails.detail
                      ? _vm._l(JSON.parse(_vm.postDetails.detail), function(
                          item,
                          inx
                        ) {
                          return _c("div", { key: inx }, [
                            item.key == "image"
                              ? _c("div", { staticClass: "mb10" }, [
                                  _c("img", {
                                    staticStyle: { "max-width": "50%" },
                                    attrs: { src: item.content, alt: "" }
                                  })
                                ])
                              : _vm._e(),
                            item.key == "web"
                              ? _c("div", { staticClass: "mb10 web" }, [
                                  _c("div", { staticClass: "link-content" }, [
                                    _c("div", { staticClass: "article-img" }, [
                                      _c("img", {
                                        attrs: {
                                          src: item.content.image,
                                          alt: ""
                                        }
                                      })
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "article-content",
                                        attrs: {
                                          href: item.content.url,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "article-name" },
                                          [_vm._v(_vm._s(item.content.title))]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "article-text ellipsis-2",
                                            staticStyle: { color: "#333" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.content.content) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            item.key == "url"
                              ? _c("div", { staticClass: "mb10 url" }, [
                                  _c("img", {
                                    staticStyle: {
                                      width: "16px",
                                      height: "16px",
                                      "margin-right": "5px"
                                    },
                                    attrs: {
                                      src:
                                        "https://tfile.melinked.com/2021/03/c2856521-972f-4b9f-9df5-2abc1ce6a384.png"
                                    }
                                  }),
                                  _c(
                                    "a",
                                    {
                                      staticStyle: {
                                        color: "rgb(34, 108, 219)"
                                      },
                                      attrs: {
                                        target: "_blank",
                                        href:
                                          typeof item.content === "string"
                                            ? JSON.parse(item.content).showLink
                                            : item.content.showLink
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          typeof item.content === "string"
                                            ? JSON.parse(item.content).showLink
                                            : item.content.showLink
                                        )
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            item.key == "text"
                              ? _c("div", { staticClass: "mb10" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.content) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e(),
                            item.key == "video"
                              ? _c("div", { staticClass: "mb10" }, [
                                  _c("video", {
                                    staticStyle: { "max-width": "50%" },
                                    attrs: { src: item.content, controls: "" }
                                  })
                                ])
                              : _vm._e()
                          ])
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "file-content point",
                        on: {
                          click: function($event) {
                            return _vm.downloadFile(_vm.fileInfo)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "article-img" }, [
                          _c("img", { attrs: { src: _vm.iconSrc(), alt: "" } })
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "article-content",
                            staticStyle: {
                              color: "#373737",
                              "font-weight": "bold"
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.fileInfo.name) +
                                "\n        "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm.postDetails.tags
                      ? _c(
                          "div",
                          { staticClass: "tagsBox" },
                          _vm._l(_vm.postDetails.tags.split(","), function(
                            tag,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "labelItem" },
                              [
                                _c("span", {
                                  staticClass: "iconfont_Me icon-tag2 labelIcon"
                                }),
                                _c("span", { staticClass: "labelContent" }, [
                                  _vm._v(_vm._s(tag))
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e(),
            _vm.documentType == 0 && _vm.webInfos.length > 0
              ? _c(
                  "div",
                  { staticClass: "link-post" },
                  [
                    _c("div", { staticClass: "article-top" }, [
                      _c(
                        "div",
                        {
                          staticClass: "user-img",
                          staticStyle: {
                            "border-radius": "50%",
                            overflow: "hidden"
                          }
                        },
                        [
                          _vm.postDetails.profilePhoto
                            ? _c("el-image", {
                                attrs: {
                                  src: _vm.postDetails.profilePhoto,
                                  "preview-src-list": [
                                    _vm.postDetails.profilePhoto
                                  ]
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticClass: "user-content" }, [
                        _c(
                          "div",
                          {
                            staticClass: "user-name",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.goPersonalCenter(
                                  _vm.postDetails.providerId
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.postDetails.name) +
                                "\n          "
                            )
                          ]
                        ),
                        _c("div", { staticClass: "user-text" }, [
                          _vm._v(_vm._s(_vm.postDetails.publishTime))
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "toTop",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toTop.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../../assets/images/toTop.png")
                              }
                            })
                          ]
                        )
                      ])
                    ]),
                    _vm.postDetails.iCanDo
                      ? _c("h2", [_vm._v(_vm._s(_vm.postDetails.iCanDo))])
                      : _vm._e(),
                    _vm._l(JSON.parse(_vm.postDetails.detail), function(
                      item,
                      inx
                    ) {
                      return _c("div", { key: inx }, [
                        item.key == "web"
                          ? _c("div", { staticClass: "mb10" }, [
                              _c("div", { staticClass: "link-content" }, [
                                _c("div", { staticClass: "article-img" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.webInfos[0].content.image,
                                      alt: ""
                                    }
                                  })
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "article-content",
                                    attrs: {
                                      href: _vm.webInfos[0].content.url,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "article-name" }, [
                                      _vm._v(
                                        _vm._s(_vm.webInfos[0].content.title)
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "article-text ellipsis-2",
                                        staticStyle: { color: "#333" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.webInfos[0].content.content
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ])
                          : _vm._e(),
                        item.key == "url"
                          ? _c("div", { staticClass: "mb10 url" }, [
                              _c("img", {
                                staticStyle: {
                                  width: "16px",
                                  height: "16px",
                                  "margin-right": "5px"
                                },
                                attrs: {
                                  "data-v-9d3a79a6": "",
                                  src:
                                    "https://tfile.melinked.com/2021/03/c2856521-972f-4b9f-9df5-2abc1ce6a384.png"
                                }
                              }),
                              _c(
                                "a",
                                {
                                  staticStyle: { color: "rgb(34, 108, 219)" },
                                  attrs: {
                                    target: "_blank",
                                    href:
                                      typeof item.content === "string"
                                        ? JSON.parse(item.content).jumpLink
                                        : item.content.jumpLink
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      typeof item.content === "string"
                                        ? JSON.parse(item.content).jumpLink
                                        : item.content.jumpLink
                                    )
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        item.key == "text"
                          ? _c("div", { staticClass: "mb10" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.content) +
                                  "\n        "
                              )
                            ])
                          : _vm._e(),
                        item.key == "video"
                          ? _c("div", { staticClass: "mb10" }, [
                              _c("video", {
                                staticStyle: { "max-width": "60%" },
                                attrs: { src: item.content, controls: "" }
                              })
                            ])
                          : _vm._e()
                      ])
                    }),
                    _vm.postDetails.tags
                      ? _c(
                          "div",
                          { staticClass: "tagsBox" },
                          _vm._l(_vm.postDetails.tags.split(","), function(
                            tag,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "labelItem" },
                              [
                                _c("span", {
                                  staticClass: "iconfont_Me icon-tag2 labelIcon"
                                }),
                                _c("span", { staticClass: "labelContent" }, [
                                  _vm._v(_vm._s(tag))
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e(),
            _vm.documentType == 0 && _vm.webInfos.length == 0
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "article-top" }, [
                      _c(
                        "div",
                        {
                          staticClass: "user-img",
                          staticStyle: {
                            "border-radius": "50%",
                            overflow: "hidden"
                          }
                        },
                        [
                          _vm.postDetails.profilePhoto
                            ? _c("el-image", {
                                attrs: {
                                  src: _vm.postDetails.profilePhoto,
                                  "preview-src-list": [
                                    _vm.postDetails.profilePhoto
                                  ]
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticClass: "user-content" }, [
                        _c(
                          "div",
                          {
                            staticClass: "user-name",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.goPersonalCenter(
                                  _vm.postDetails.providerId
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.postDetails.name) +
                                "\n          "
                            )
                          ]
                        ),
                        _c("div", { staticClass: "user-text" }, [
                          _vm._v(_vm._s(_vm.postDetails.publishTime))
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "toTop",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toTop.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../../assets/images/toTop.png")
                              }
                            })
                          ]
                        )
                      ])
                    ]),
                    _vm.postDetails.iCanDo
                      ? _c("h2", [_vm._v(_vm._s(_vm.postDetails.iCanDo))])
                      : _vm._e(),
                    _vm._l(JSON.parse(_vm.postDetails.detail), function(
                      item,
                      inx
                    ) {
                      return _c("div", { key: inx }, [
                        item.key == "image"
                          ? _c("div", { staticClass: "mb10" }, [
                              _c("img", {
                                staticStyle: {
                                  "max-width": "60%",
                                  "border-radius": "4px"
                                },
                                attrs: { src: item.content, alt: "" }
                              })
                            ])
                          : _vm._e(),
                        item.key == "web"
                          ? _c("div", { staticClass: "mb10" }, [
                              _c("div", { staticClass: "link-content" }, [
                                _c("div", { staticClass: "article-img" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.webInfos[0].content.image,
                                      alt: ""
                                    }
                                  })
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "article-content",
                                    attrs: {
                                      href: _vm.webInfos[0].content.url,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "article-name" }, [
                                      _vm._v(
                                        _vm._s(_vm.webInfos[0].content.title)
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "article-text ellipsis-2",
                                        staticStyle: { color: "#333" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.webInfos[0].content.content
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ])
                          : _vm._e(),
                        item.key == "url"
                          ? _c("div", { staticClass: "mb10 url" }, [
                              _c("img", {
                                staticStyle: {
                                  width: "16px",
                                  height: "16px",
                                  "margin-right": "5px"
                                },
                                attrs: {
                                  "data-v-9d3a79a6": "",
                                  src:
                                    "https://tfile.melinked.com/2021/03/c2856521-972f-4b9f-9df5-2abc1ce6a384.png"
                                }
                              }),
                              _c(
                                "a",
                                {
                                  staticStyle: { color: "rgb(34, 108, 219)" },
                                  attrs: {
                                    target: "_blank",
                                    href:
                                      typeof item.content === "string"
                                        ? JSON.parse(item.content).showLink
                                        : item.content.showLink
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      typeof item.content === "string"
                                        ? JSON.parse(item.content).showLink
                                        : item.content.showLink
                                    )
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        item.key == "text"
                          ? _c("div", { staticClass: "mb10" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.content) +
                                  "\n        "
                              )
                            ])
                          : _vm._e(),
                        item.key == "video"
                          ? _c("div", { staticClass: "mb10" }, [
                              _c("video", {
                                staticStyle: {
                                  "max-width": "60%",
                                  "border-radius": "4px"
                                },
                                attrs: { src: item.content, controls: "" }
                              })
                            ])
                          : _vm._e()
                      ])
                    }),
                    _vm.postDetails.tags
                      ? _c(
                          "div",
                          { staticClass: "tagsBox" },
                          _vm._l(_vm.postDetails.tags.split(","), function(
                            tag,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "labelItem" },
                              [
                                _c("span", {
                                  staticClass: "iconfont_Me icon-tag2 labelIcon"
                                }),
                                _c("span", { staticClass: "labelContent" }, [
                                  _vm._v(_vm._s(tag))
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.type == 3
        ? _c(
            "div",
            { staticClass: "examine-wrap user-wrap" },
            [
              _c("div", { staticClass: "user-top" }, [
                _c(
                  "div",
                  {
                    staticClass: "user-img",
                    staticStyle: { "border-radius": "50%", overflow: "hidden" }
                  },
                  [
                    _vm.personalInfo.profilePhoto
                      ? _c("el-image", {
                          attrs: {
                            src: _vm.personalInfo.profilePhoto,
                            "preview-src-list": [_vm.personalInfo.profilePhoto]
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "user-content ellipsis-2" },
                  [
                    _vm.personalInfo.vipStatus
                      ? _c(
                          "span",
                          { staticClass: "xvipName" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "vipDisplayName",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.goPersonalCenter(
                                      _vm.postDetails.providerId
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.personalInfo.name))]
                            ),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("paidMember"),
                                  placement: "bottom",
                                  "popper-class": "me-tooltip-effect"
                                }
                              },
                              [
                                _c("el-image", {
                                  staticClass: "vipIcon",
                                  attrs: {
                                    src: require("@/assets/images/pay/masonry.png"),
                                    fit: "fill"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.handleClickVip(
                                        _vm.personalInfo.providerId
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : [
                          _c(
                            "div",
                            {
                              staticClass: "user-name",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.goPersonalCenter(
                                    _vm.postDetails.providerId
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.personalInfo.name) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                    _c("div", { staticClass: "user-text" }, [
                      _vm._v("ID: " + _vm._s(_vm.personalInfo.ucId))
                    ])
                  ],
                  2
                )
              ]),
              _vm.personalInfo.skills
                ? _c(
                    "div",
                    { staticClass: "tagsBox" },
                    _vm._l(_vm.personalInfo.skills.split(","), function(
                      tag,
                      index
                    ) {
                      return _c(
                        "div",
                        { key: index, staticClass: "labelItem" },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me icon-tag2 labelIcon"
                          }),
                          _c("span", { staticClass: "labelContent" }, [
                            _vm._v(_vm._s(tag))
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.personalInfo.intro
                ? _vm._l(JSON.parse(_vm.personalInfo.intro), function(item) {
                    return _c(
                      "div",
                      { key: item.content, staticClass: "introductionBox" },
                      [
                        item.key == "text"
                          ? _c("div", { staticClass: "mb10" }, [
                              _vm._v(_vm._s(item.content))
                            ])
                          : _vm._e(),
                        item.key == "title"
                          ? _c("div", { staticClass: "mb10 user-title" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.content) +
                                  "\n        "
                              )
                            ])
                          : _vm._e(),
                        item.key == "image"
                          ? _c(
                              "div",
                              { staticClass: "img-wrap mb10" },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: item.content,
                                    "preview-src-list": [item.content]
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        item.key == "video"
                          ? _c(
                              "div",
                              {
                                staticClass: "mb10",
                                staticStyle: { "max-width": "60%" }
                              },
                              [
                                _c("video", {
                                  staticStyle: {
                                    width: "100%",
                                    "border-radius": "4px"
                                  },
                                  attrs: { src: item.content, controls: "" }
                                })
                              ]
                            )
                          : _vm._e(),
                        item.key == "file"
                          ? _c("div", { staticClass: "file-wrap mb10" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "file-content",
                                  on: {
                                    click: function($event) {
                                      return _vm.downloadFile(item, "user")
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "article-img" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.iconSrc(item.fileName),
                                        alt: ""
                                      }
                                    })
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "article-content ellipsis-2"
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.fileName) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  })
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.type == 4
        ? _c("div", { staticClass: "examine-wrap group-wrap" }, [
            _c("div", { staticClass: "group-top" }, [
              _c(
                "div",
                { staticClass: "group-img" },
                [
                  _c("el-image", {
                    attrs: {
                      src: _vm.groupInfo.group_header
                        ? _vm.groupInfo.group_header
                        : _vm.groupInfo.groupHeader,
                      alt: "",
                      "preview-src-list": [
                        _vm.groupInfo.group_header || _vm.groupInfo.groupHeader
                      ]
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "group-content ellipsis-2" }, [
                _c("div", { staticClass: "group-name" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.groupInfo.group_name
                          ? _vm.groupInfo.group_name
                          : _vm.groupInfo.groupName
                      ) +
                      "\n        "
                  )
                ]),
                _c("div", { staticClass: "joinTime" }, [
                  _vm._v(_vm._s(_vm.groupInfo.joinTime))
                ])
              ])
            ]),
            _vm.groupInfo.groupTag
              ? _c(
                  "div",
                  { staticClass: "tagsBox" },
                  _vm._l(_vm.groupInfo.groupTag.split(","), function(
                    tag,
                    index
                  ) {
                    return _c("div", { key: index, staticClass: "labelItem" }, [
                      _c("span", {
                        staticClass: "iconfont_Me icon-tag2 labelIcon"
                      }),
                      _c("span", { staticClass: "labelContent" }, [
                        _vm._v(_vm._s(tag))
                      ])
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _c("div", {
              staticClass: "introductionBox",
              domProps: { innerHTML: _vm._s(_vm.groupInfo.groupInfo) }
            })
          ])
        : _vm._e(),
      _c("div", { staticClass: "operat" }, [
        _c("div", { staticClass: "btn", on: { click: _vm.handle_delete } }, [
          _c("i", { staticClass: "iconfont_Me icon-delet" })
        ]),
        _c("div", { staticClass: "btn", on: { click: _vm.handle_pass } }, [
          _c("i", { staticClass: "iconfont_Me icon-check" })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
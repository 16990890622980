<!-- 内容审核操作弹窗 -->
<template>
  <el-dialog
    :visible.sync="$store.state.examine.examinePopoverVisible"
    custom-class="examinePopover"
    :before-close="beforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    center
  >
    <!-- <div class="complaint-header">{{ $t('complaint')}}</div> -->
    <!-- 投诉正文 -->
    <!-- 帖子 -->
    <div v-if="type == 1 && postDetails" class="examine-wrap post-wrap">
      <!-- 视频类帖子 -->
      <div v-if="documentType == 1" class="video-post">
        <div class="article-top">
          <div class="user-img" style="border-radius: 50%; overflow: hidden">
            <!-- <img :src="postDetails.profilePhoto" alt="" /> -->
            <el-image
              v-if="postDetails.profilePhoto"
              :src="postDetails.profilePhoto"
              :preview-src-list="[postDetails.profilePhoto]"
            >
            </el-image>
          </div>
          <div class="user-content ellipsis-2">
            <span v-if="postDetails.vipStatus" class="xvipName">
              <span
                class="vipDisplayName"
                @click.stop="goPersonalCenter(postDetails.providerId)"
                >{{ postDetails.name }}</span
              >
              <el-tooltip
                effect="dark"
                :content="$t('paidMember')"
                placement="bottom"
                popper-class="me-tooltip-effect"
              >
                <el-image
                  class="vipIcon"
                  :src="require('@/assets/images/pay/masonry.png')"
                  fit="fill"
                  @click.stop="handleClickVip(postDetails.providerId)"
                >
                </el-image>
              </el-tooltip>
            </span>
            <template v-else>
              <div
                class="user-name"
                @click.stop="goPersonalCenter(postDetails.providerId)"
              >
                {{ postDetails.name }}
              </div>
            </template>
            <div class="user-text">{{ postDetails.publishTime }}</div>
            <div class="toTop" @click.stop="toTop">
              <img :src="require('../../../assets/images/toTop.png')" />
            </div>
          </div>
        </div>
        <div class="article-title">{{ postDetails.iCanDo }}</div>
        <div
          class="article-content"
          v-html="postDetails.richDetail"
          v-if="postDetails.richDetail"
        ></div>
        <div
          class="article-content"
          v-if="
            !postDetails.richDetail &&
              postDetails.detail &&
              JSON.parse(postDetails.detail)[0]
          "
          v-html="JSON.parse(postDetails.detail)[0].content"
        ></div>
        <div class="article-video">
          <video
            :src="vodie"
            style="max-width:100%;height:100%"
            id="examVideo"
            controls
          ></video>
        </div>
        <div class="tagsBox" v-if="postDetails.tags">
          <div
            class="labelItem"
            v-for="(tag, index) in postDetails.tags.split(',')"
            :key="index"
          >
            <span class="iconfont_Me icon-tag2 labelIcon"></span>
            <span class="labelContent">{{ tag }}</span>
          </div>
        </div>
      </div>
      <!-- 图片帖子 -->
      <div v-if="documentType == 3" class="pic-post">
        <div class="article-top">
          <div class="user-img" style="border-radius: 50%; overflow: hidden">
            <!-- <img :src="postDetails.profilePhoto" alt="" /> -->
            <el-image
              v-if="postDetails.profilePhoto"
              :src="postDetails.profilePhoto"
              :preview-src-list="[postDetails.profilePhoto]"
            >
            </el-image>
          </div>
          <div class="user-content ellipsis-2">
            <span v-if="postDetails.vipStatus" class="xvipName">
              <span
                class="vipDisplayName"
                @click.stop="goPersonalCenter(postDetails.providerId)"
                >{{ postDetails.name }}</span
              >
              <el-tooltip
                effect="dark"
                :content="$t('paidMember')"
                placement="bottom"
                popper-class="me-tooltip-effect"
              >
                <el-image
                  class="vipIcon"
                  :src="require('@/assets/images/pay/masonry.png')"
                  fit="fill"
                  @click.stop="handleClickVip(postDetails.providerId)"
                >
                </el-image>
              </el-tooltip>
            </span>
            <template v-else>
              <div
                class="user-name"
                @click.stop="goPersonalCenter(postDetails.providerId)"
              >
                {{ postDetails.name }}
              </div>
            </template>
            <div class="user-text">{{ postDetails.publishTime }}</div>
            <div class="toTop" @click.stop="toTop">
              <img :src="require('../../../assets/images/toTop.png')" />
            </div>
          </div>
        </div>

        <div class="article-title">{{ postDetails.iCanDo }}</div>
        <div
          class="article-content"
          v-html="postDetails.richDetail"
          v-if="postDetails.richDetail"
        ></div>
        <div
          class="article-content"
          v-if="
            postDetails.detail &&
              postDetails.detail != '[]' &&
              !postDetails.richDetail
          "
        >
          <!-- v-html="JSON.parse(postDetails.detail)[0].content" -->
          <div
            v-for="(item, dIndx) in JSON.parse(postDetails.detail)"
            :key="dIndx"
          >
            <div class="cont" v-if="item.key == 'text'">
              {{ item.content }}
            </div>
            <div class="cont" v-if="item.key == 'image'">
              <img
                :src="item.content"
                style="width:auto;height: 77px;"
                alt=""
              />
            </div>
            <div class="cont" v-if="item.key == 'web'">
              <a class="_a" :href="item.content.url" target="_black">
                <div class="webImag">
                  <img :src="item.content.image" alt="" />
                </div>
                <div class="webInfo">
                  <div class="webTitle">{{ item.content.title }}</div>
                  <div class="webCont">
                    {{ item.content.content }}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="article-img">
          <el-image
            v-for="(item, indx) in postDetails.attachments"
            :src="item.path"
            :key="indx"
            :preview-src-list="[item.path]"
          />
        </div>
        <div class="tagsBox" v-if="postDetails.tags">
          <div
            class="labelItem"
            v-for="(tag, index) in postDetails.tags.split(',')"
            :key="index"
          >
            <span class="iconfont_Me icon-tag2 labelIcon"></span>
            <span class="labelContent">{{ tag }}</span>
          </div>
        </div>
      </div>
      <!-- 文件类型帖子 -->
      <div v-if="documentType == 4" class="file-post">
        <div class="article-top">
          <div class="user-img" style="border-radius: 50%; overflow: hidden">
            <el-image
              v-if="postDetails.profilePhoto"
              :src="postDetails.profilePhoto"
              :preview-src-list="[postDetails.profilePhoto]"
            >
            </el-image>
            <!-- <img :src="postDetails.profilePhoto" alt="" /> -->
          </div>
          <div class="user-content">
            <div
              class="user-name"
              @click.stop="goPersonalCenter(postDetails.providerId)"
            >
              {{ postDetails.name }}
            </div>
            <div class="user-text">{{ postDetails.publishTime }}</div>
            <div class="toTop" @click.stop="toTop">
              <img :src="require('../../../assets/images/toTop.png')" />
            </div>
          </div>
        </div>
        <h2 v-if="postDetails.iCanDo">{{ postDetails.iCanDo }}</h2>
        <template v-if="postDetails.detail">
          <div v-for="(item, inx) in JSON.parse(postDetails.detail)" :key="inx">
            <div v-if="item.key == 'image'" class="mb10">
              <img :src="item.content" style="max-width: 50%;" alt="" />
            </div>
            <div v-if="item.key == 'web'" class="mb10 web">
              <div class="link-content">
                <div class="article-img">
                  <img :src="item.content.image" alt="" />
                </div>
                <a
                  class="article-content"
                  :href="item.content.url"
                  target="_blank"
                >
                  <div class="article-name">{{ item.content.title }}</div>
                  <div class="article-text ellipsis-2" style="color:#333">
                    {{ item.content.content }}
                  </div>
                </a>
              </div>
            </div>
            <div v-if="item.key == 'url'" class="mb10 url">
              <img
                src="https://tfile.melinked.com/2021/03/c2856521-972f-4b9f-9df5-2abc1ce6a384.png"
                style="width: 16px; height: 16px; margin-right: 5px;"
              />
              <a
                target="_blank"
                :href="
                  typeof item.content === 'string'
                    ? JSON.parse(item.content).showLink
                    : item.content.showLink
                "
                style="color:rgb(34, 108, 219)"
                >{{
                  typeof item.content === "string"
                    ? JSON.parse(item.content).showLink
                    : item.content.showLink
                }}</a
              >
            </div>
            <div v-if="item.key == 'text'" class="mb10">
              {{ item.content }}
            </div>
            <div v-if="item.key == 'video'" class="mb10">
              <video
                :src="item.content"
                style="max-width: 50%;"
                controls
              ></video>
            </div>
          </div>
        </template>
        <div class="file-content point" @click="downloadFile(fileInfo)">
          <div class="article-img">
            <img :src="iconSrc()" alt="" />
          </div>
          <div class="article-content" style="color:#373737;font-weight: bold;">
            {{ fileInfo.name }}
          </div>
        </div>

        <div class="tagsBox" v-if="postDetails.tags">
          <div
            class="labelItem"
            v-for="(tag, index) in postDetails.tags.split(',')"
            :key="index"
          >
            <span class="iconfont_Me icon-tag2 labelIcon"></span>
            <span class="labelContent">{{ tag }}</span>
          </div>
        </div>
      </div>
      <!-- 链接类型帖子 -->
      <div class="link-post" v-if="documentType == 0 && webInfos.length > 0">
        <div class="article-top">
          <div class="user-img" style="border-radius: 50%; overflow: hidden">
            <!-- <img :src="postDetails.profilePhoto" alt="" /> -->
            <el-image
              v-if="postDetails.profilePhoto"
              :src="postDetails.profilePhoto"
              :preview-src-list="[postDetails.profilePhoto]"
            >
            </el-image>
          </div>
          <div class="user-content">
            <div
              class="user-name"
              @click.stop="goPersonalCenter(postDetails.providerId)"
            >
              {{ postDetails.name }}
            </div>
            <div class="user-text">{{ postDetails.publishTime }}</div>
            <div class="toTop" @click.stop="toTop">
              <img :src="require('../../../assets/images/toTop.png')" />
            </div>
          </div>
        </div>
        <h2 v-if="postDetails.iCanDo">{{ postDetails.iCanDo }}</h2>
        <div v-for="(item, inx) in JSON.parse(postDetails.detail)" :key="inx">
          <div v-if="item.key == 'web'" class="mb10">
            <div class="link-content">
              <div class="article-img">
                <img :src="webInfos[0].content.image" alt="" />
              </div>
              <a
                class="article-content"
                :href="webInfos[0].content.url"
                target="_blank"
              >
                <div class="article-name">{{ webInfos[0].content.title }}</div>
                <div class="article-text ellipsis-2" style="color:#333">
                  {{ webInfos[0].content.content }}
                </div>
              </a>
            </div>
          </div>
          <div v-if="item.key == 'url'" class="mb10 url">
            <img
              data-v-9d3a79a6=""
              src="https://tfile.melinked.com/2021/03/c2856521-972f-4b9f-9df5-2abc1ce6a384.png"
              style="width: 16px; height: 16px; margin-right: 5px;"
            />
            <a
              target="_blank"
              :href="
                typeof item.content === 'string'
                  ? JSON.parse(item.content).jumpLink
                  : item.content.jumpLink
              "
              style="color:rgb(34, 108, 219)"
              >{{
                typeof item.content === "string"
                  ? JSON.parse(item.content).jumpLink
                  : item.content.jumpLink
              }}</a
            >
          </div>
          <div v-if="item.key == 'text'" class="mb10">
            {{ item.content }}
          </div>
          <div v-if="item.key == 'video'" class="mb10">
            <video :src="item.content" style="max-width: 60%;" controls></video>
          </div>
        </div>
        <div class="tagsBox" v-if="postDetails.tags">
          <div
            class="labelItem"
            v-for="(tag, index) in postDetails.tags.split(',')"
            :key="index"
          >
            <span class="iconfont_Me icon-tag2 labelIcon"></span>
            <span class="labelContent">{{ tag }}</span>
          </div>
        </div>
      </div>
      <!-- 纯文字类型帖子 -->
      <div v-if="documentType == 0 && webInfos.length == 0">
        <div class="article-top">
          <div class="user-img" style="border-radius: 50%; overflow: hidden">
            <el-image
              v-if="postDetails.profilePhoto"
              :src="postDetails.profilePhoto"
              :preview-src-list="[postDetails.profilePhoto]"
            >
            </el-image>
          </div>
          <div class="user-content">
            <div
              class="user-name"
              @click.stop="goPersonalCenter(postDetails.providerId)"
            >
              {{ postDetails.name }}
            </div>
            <div class="user-text">{{ postDetails.publishTime }}</div>
            <div class="toTop" @click.stop="toTop">
              <img :src="require('../../../assets/images/toTop.png')" />
            </div>
          </div>
        </div>

        <h2 v-if="postDetails.iCanDo">{{ postDetails.iCanDo }}</h2>
        <div v-for="(item, inx) in JSON.parse(postDetails.detail)" :key="inx">
          <div v-if="item.key == 'image'" class="mb10">
            <img
              :src="item.content"
              style="max-width: 60%;border-radius: 4px;"
              alt=""
            />
          </div>
          <div v-if="item.key == 'web'" class="mb10">
            <div class="link-content">
              <div class="article-img">
                <img :src="webInfos[0].content.image" alt="" />
              </div>
              <a
                class="article-content"
                :href="webInfos[0].content.url"
                target="_blank"
              >
                <div class="article-name">{{ webInfos[0].content.title }}</div>
                <div class="article-text ellipsis-2" style="color:#333">
                  {{ webInfos[0].content.content }}
                </div>
              </a>
            </div>
          </div>
          <div v-if="item.key == 'url'" class="mb10 url">
            <img
              data-v-9d3a79a6=""
              src="https://tfile.melinked.com/2021/03/c2856521-972f-4b9f-9df5-2abc1ce6a384.png"
              style="width: 16px; height: 16px; margin-right: 5px;"
            />

            <a
              target="_blank"
              :href="
                typeof item.content === 'string'
                  ? JSON.parse(item.content).showLink
                  : item.content.showLink
              "
              style="color:rgb(34, 108, 219)"
              >{{
                typeof item.content === "string"
                  ? JSON.parse(item.content).showLink
                  : item.content.showLink
              }}</a
            >
          </div>
          <div v-if="item.key == 'text'" class="mb10">
            {{ item.content }}
          </div>
          <div v-if="item.key == 'video'" class="mb10">
            <video
              :src="item.content"
              style="max-width: 60%;border-radius: 4px;"
              controls
            ></video>
          </div>
        </div>
        <div class="tagsBox" v-if="postDetails.tags">
          <div
            class="labelItem"
            v-for="(tag, index) in postDetails.tags.split(',')"
            :key="index"
          >
            <span class="iconfont_Me icon-tag2 labelIcon"></span>
            <span class="labelContent">{{ tag }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 用户 -->
    <div v-if="type == 3" class="examine-wrap user-wrap">
      <div class="user-top">
        <div class="user-img" style="border-radius: 50%; overflow: hidden">
          <el-image
            v-if="personalInfo.profilePhoto"
            :src="personalInfo.profilePhoto"
            :preview-src-list="[personalInfo.profilePhoto]"
          >
          </el-image>
        </div>
        <div class="user-content ellipsis-2">
          <span v-if="personalInfo.vipStatus" class="xvipName">
            <span
              class="vipDisplayName"
              @click.stop="goPersonalCenter(postDetails.providerId)"
              >{{ personalInfo.name }}</span
            >
            <el-tooltip
              effect="dark"
              :content="$t('paidMember')"
              placement="bottom"
              popper-class="me-tooltip-effect"
            >
              <el-image
                class="vipIcon"
                :src="require('@/assets/images/pay/masonry.png')"
                fit="fill"
                @click.stop="handleClickVip(personalInfo.providerId)"
              >
              </el-image>
            </el-tooltip>
          </span>
          <template v-else>
            <div
              class="user-name"
              @click.stop="goPersonalCenter(postDetails.providerId)"
            >
              {{ personalInfo.name }}
            </div>
          </template>
          <div class="user-text">ID: {{ personalInfo.ucId }}</div>
        </div>
      </div>
      <div class="tagsBox" v-if="personalInfo.skills">
        <div
          class="labelItem"
          v-for="(tag, index) in personalInfo.skills.split(',')"
          :key="index"
        >
          <span class="iconfont_Me icon-tag2 labelIcon"></span>
          <span class="labelContent">{{ tag }}</span>
        </div>
      </div>
      <template v-if="personalInfo.intro">
        <div
          class="introductionBox"
          v-for="item in JSON.parse(personalInfo.intro)"
          :key="item.content"
        >
          <div v-if="item.key == 'text'" class="mb10">{{ item.content }}</div>
          <div v-if="item.key == 'title'" class="mb10 user-title">
            {{ item.content }}
          </div>
          <div v-if="item.key == 'image'" class="img-wrap mb10">
            <el-image :src="item.content" :preview-src-list="[item.content]" />
          </div>
          <div v-if="item.key == 'video'" style="max-width:60%" class="mb10">
            <video
              :src="item.content"
              style="width:100%;border-radius: 4px;"
              controls
            ></video>
          </div>
          <div v-if="item.key == 'file'" class="file-wrap mb10">
            <div class="file-content" @click="downloadFile(item, 'user')">
              <div class="article-img">
                <img :src="iconSrc(item.fileName)" alt="" />
              </div>
              <div class="article-content ellipsis-2">
                {{ item.fileName }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <!-- 群 -->
    <div v-if="type == 4" class="examine-wrap group-wrap">
      <div class="group-top">
        <div class="group-img">
          <el-image
            :src="
              groupInfo.group_header
                ? groupInfo.group_header
                : groupInfo.groupHeader
            "
            alt=""
            :preview-src-list="[
              groupInfo.group_header || groupInfo.groupHeader,
            ]"
          />
        </div>
        <div class="group-content ellipsis-2">
          <div class="group-name">
            {{
              groupInfo.group_name ? groupInfo.group_name : groupInfo.groupName
            }}
          </div>
          <div class="joinTime">{{ groupInfo.joinTime }}</div>
        </div>
      </div>
      <div class="tagsBox" v-if="groupInfo.groupTag">
        <div
          class="labelItem"
          v-for="(tag, index) in groupInfo.groupTag.split(',')"
          :key="index"
        >
          <span class="iconfont_Me icon-tag2 labelIcon"></span>
          <span class="labelContent">{{ tag }}</span>
        </div>
      </div>
      <div class="introductionBox" v-html="groupInfo.groupInfo"></div>
    </div>
    <div class="operat">
      <div class="btn" @click="handle_delete">
        <i class="iconfont_Me icon-delet"></i>
      </div>
      <div class="btn" @click="handle_pass">
        <i class="iconfont_Me icon-check"></i>
      </div>
    </div>
  </el-dialog>
</template>
<script>
let baseUrl = "https://pic.melinked.com";
import { audit } from "@/api/newVersion/audit";
import { mapMutations } from "vuex";
import { makePostTop } from "@/api/newVersion/postContentDetails";
export default {
  data() {
    return {
      type: null,
      userInfo: null,
      groupInfo: null,
      radio: null,
      textarea: "",
      postDetails: null,
      content: null,
      comment: null,
      loading: false,
      examineData: null,
    };
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    ...mapMutations([
      "setExaminePopoverVisible",
      "setNeedUpdateAuditList",
      "setDeleteDialogVisible",
      "setDeleteType",
      "setDeletePostId",
      "setDeleteGroupId",
      "setDeleteProviderId",
    ]),
    goPersonalCenter(id) {
      this.routeJump({
        name: "personalCenter",
        params: { id: id },
      });
    },
    clearData() {
      this.postDetails = null;
      this.personalInfo = null;
      this.groupInfo = null;
      this.comment = null;
    },
    toTop() {
      if (this.postDetails.gigId) {
        this.$confirm("是否确认置顶帖子？", " ", {
          distinguishCancelAndClose: true,
          customClass: "joinTips",
          confirmButtonText: " ",
          cancelButtonText: " ",
          center: true,
          showClose: true,
          cancelButtonClass: "el-icon-close my-confirm-btn",
          confirmButtonClass: "el-icon-check my-confirm-btn",
          showCancelButton: true,
          type: "",
        })
          .then(() => {
            this.$nextTick(async () => {
              const result = await makePostTop(this.postDetails.gigId);
              if (result.code == 200) {
                this.$message({
                  type: "success",
                  message: "置顶成功",
                });
              } else {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getDetails() {
      let examineData = this.$store.state.examine.examineData;
      this.examineData = examineData;
      let targetType = examineData.targetType;
      this.type = targetType;
      // 1：帖子；11：帖子评论；3：个人；31：个人评论；4：群；41：群评论
      if (targetType == 1) {
        this.postDetails = examineData.targetData;
        this.documentType = this.postDetails.documentType;
        this.details =
          this.postDetails.detail && JSON.parse(this.postDetails.detail);
        this.content = (this.details[0] && this.details[0].content) || "";
        // 图片与文件类型的帖子
        if (this.documentType == 3 || this.documentType == 4) {
          this.pic = this.postDetails.attachments[0].path;
          this.fileInfo = this.postDetails.attachments[0];
        }
        // 链接类型的帖子
        if (this.documentType == 0) {
          this.webInfos = this.details.filter((item) => item.key == "web");
        }
        // 视频类型的帖子
        if (this.documentType == 1) {
          this.pic =
            this.postDetails.attachments[0].path + "?vframe/jpg/offset/0";
          this.vodie = this.postDetails.attachments[0].path;
        }
      }
      // 人
      if (targetType == 3) {
        this.personalInfo = examineData.targetData;
        if (this.personalInfo && this.personalInfo.profilePhoto) {
          let reg = /(http|https):\/\/\S*/;
          let isA = reg.test(this.personalInfo.profilePhoto);
          if (!isA) {
            this.personalInfo.profilePhoto =
              baseUrl + "/" + this.personalInfo.profilePhoto;
          }
        }
        if (this.personalInfo.country) {
          this.countryPic = `../../../assets/images/newCountrys/country_flag_${this.personalInfo.country}.png`;
        }
      }
      //群
      if (targetType == 4) {
        this.groupInfo = examineData.targetData;
        let reg = /(http|https):\/\/\S*/;
        let isA = reg.test(this.groupInfo.groupHeader);
        if (!isA) {
          this.groupInfo.groupHeader =
            baseUrl + "/" + this.groupInfo.groupHeader;
        }
      }
    },
    //取消操作
    dialogVisible() {
      this.setExaminePopoverVisible(false);
    },
    beforeClose() {
      this.dialogVisible();
    },
    handle_delete() {
      this.$store.commit("setNeedUpdateAuditList", false);
      // 1：帖子；11：帖子评论；3：个人；31：个人评论；4：群；41：群评论
      const targetType = this.examineData.targetType;
      if (targetType == 1) {
        this.setDeleteType("post");
        this.setDeletePostId(this.examineData.targetData.gigId);
      } else if (targetType == 3) {
        this.setDeleteType("user");
        this.setDeleteProviderId(this.examineData.targetData.providerId);
      } else if (targetType == 4) {
        this.setDeleteType("group");
        this.setDeleteGroupId(this.examineData.targetData.groupId);
      } else {
        // 所有评论在少数据里面去处理
      }
      this.setDeleteDialogVisible(true);
    },
    handle_pass() {
      this.confirm(
        null,
        this.$t("isApproval"),
        {
          type: "none",
        },
        this.passPost
      );
    },
    async passPost() {
      this.setNeedUpdateAuditList(false);
      let params = { auditIds: this.examineData.id, auditStatus: 2 };
      let result = await audit(params);
      if (result.code == 200) {
        this.$message({
          message: "success",
          type: "success",
        });
        // 这里审核通过，关闭弹窗，刷新列表页数据
        this.dialogVisible();
        this.setNeedUpdateAuditList(true);
      } else {
        this.$message({
          message: result.message,
          type: "error",
        });
      }
    },
    iconSrc(name = this.fileInfo.name) {
      if (this.judgment(name, "txt")) {
        return require("../../../assets/images/newPublish/text.png");
      } else if (this.judgment(name, "doc") || this.judgment(name, "docx")) {
        return require("../../../assets/images/newPublish/word.png");
      } else if (this.judgment(name, "pdf")) {
        return require("../../../assets/images/newPublish/pdf.png");
      } else if (this.judgment(name, "xlsx") || this.judgment(name, "xls")) {
        return require("../../../assets/images/newPublish/excel.png");
      } else if (this.judgment(name, "pptx") || this.judgment(name, "ppt")) {
        return require("../../../assets/images/newPublish/ppt.png");
      } else if (
        this.judgment(name, "tar") ||
        this.judgment(name, "7z") ||
        this.judgment(name, "bz2") ||
        this.judgment(name, "gz") ||
        this.judgment(name, "wim") ||
        this.judgment(name, "xz") ||
        this.judgment(name, "zip")
      ) {
        return require("../../../assets/images/newPublish/compressed.png");
      } else {
        return require("../../../assets/images/newPublish/unknown.png");
      }
    },
    // 判断是否是这个文件
    judgment(text, type) {
      const handlerText = text.toLowerCase();
      let typeLength = handlerText.length - handlerText.lastIndexOf(type);
      return handlerText.lastIndexOf(type) != -1 && typeLength == type.length;
    },
    // 下载文件
    downloadFile(fileInfo, type) {
      if (type == "user") {
        fileInfo.path = fileInfo.content;
        fileInfo.name = fileInfo.fileName;
      }
      let downloadFileUrl = `${this.env(
        "BASEURL"
      )}/rest/user/download?downloadUrl=${this.formartImageUrl(
        fileInfo.path
      )}&fileName=${fileInfo.name.replace(/<\/?.+?\/?>/g, "")}`;
      if (fileInfo.name.indexOf("%") != -1) {
        if (fileInfo.name.indexOf("pdf") != -1) {
          window.open(fileInfo.path, "_blank");
        } else {
          var a = document.createElement("a"); //页面上创建一个标签
          a.setAttribute("href", fileInfo.path); //设置a 标签的href，
          a.setAttribute("target", "_blank"); //设置a 标签的download 属性，并限定下载文件名。。
          a.click(); //出发a点击事件，下载文件
          a.remove();
        }
      } else {
        window.open(downloadFileUrl, "_blank");
      }
    },
  },
};
</script>

<style lang="scss">
.mb10 {
  margin-bottom: 10px;
}

.ellipsis-2 {
  overflow: hidden; //超出文本隐藏
  text-overflow: ellipsis; ///超出部分省略号显示
  display: -webkit-box; //弹性盒模型
  -webkit-box-orient: vertical; //上下垂直
  -webkit-line-clamp: 2;
}

.examinePopover {
  max-width: 745px !important;
  height: auto !important;
  padding: 20px 40px 30px 40px !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  // overflow: hidden;
  // scrollbar-width:none;
  .point {
    cursor: pointer;
  }

  .url {
    display: flex;
    align-items: center;
  }

  .joinTime {
    font-size: 12px;
    color: #8f8f8f;
  }

  .examine-wrap {
    &.group-wrap {
      max-height: 60vh;
      overflow-y: auto;
      scrollbar-width: thin;

      .group-top {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .group-img {
          width: 82px;
          height: 82px;
          border-radius: 6px;
          overflow: hidden;
          margin-right: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .group-name {
          margin-bottom: 12px;
          font-size: 17px;
          color: #333333;
        }
      }

      //简介
      .introductionBox {
        font-size: 14px;
        color: #666666;
        line-height: 22px;
      }
    }

    //用户
    &.user-wrap {
      max-height: 60vh;
      overflow-y: auto;
      scrollbar-width: thin;

      .user-title {
        font-size: 18px;
        font-weight: bold;
      }

      .user-top {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .user-img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 12px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .user-content {
          .xvipName {
            max-width: 130px;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            .vipDisplayName {
              max-width: 180px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .vipIcon {
              width: 17px;
              height: 14px;
              margin-left: 6px;
            }
          }
          .user-name {
            font-size: 14px;
            font-weight: 700;
            color: #373737;
            margin-bottom: 4px;
          }

          .user-text {
            font-size: 12px;
            color: #666666;
          }
        }
      }

      .img-wrap {
        max-width: 60%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .file-content {
        width: 343px;
        background: #f4f5f7;
        padding: 10px 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;

        .article-img {
          margin-right: 16px;
        }
      }
    }

    //帖子
    &.post-wrap {
      max-height: 60vh;
      overflow-y: auto;
      scrollbar-width: thin;

      .tagsBox {
        margin-top: 6px;
      }

      .article-top {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .user-img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 12px;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .user-content {
          min-width: 0;
          flex: 1;
          position: relative;
          .xvipName {
            max-width: 130px;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            .vipDisplayName {
              max-width: 180px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .vipIcon {
              width: 17px;
              height: 14px;
              margin-left: 6px;
            }
          }
          .user-name {
            font-size: 14px;
            font-weight: 700;
            color: #373737;
            margin-bottom: 4px;
          }

          .user-text {
            font-size: 12px;
            color: #666666;
          }

          .toTop {
            width: 24px;
            height: 24px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translate3d(0, -50%, 0);
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .pic-post {
        .article-img {
          width: 70%;
          border-radius: 6px;
          overflow: hidden;

          img {
            width: 100%;
          }
        }

        .article-title {
          font-size: 20px;
          color: #000000;
          margin-bottom: 12px;
        }

        .article-content {
          margin-bottom: 12px;

          img {
            max-width: 100%;
            display: block;
          }

          .cont {
            margin-bottom: 10px;
          }

          ._a {
            max-width: 60%;
            background: rgb(247, 247, 247);
            border-radius: 8px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            box-sizing: border-box;
            padding: 14px 24px;

            .webImag {
              max-width: 64px;
              // height: 64px;
              margin-right: 25px;
              flex-shrink: 0;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .webTitle {
              font-size: 15px;
              font-weight: 700;
              color: #1a051d;
            }

            .webCont {
              width: 100%;
              text-align: left;
              font-size: 12px;
              color: rgb(55, 55, 55);
              margin-top: 9px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      .file-post {
        .file-content {
          width: 343px;
          background: #f7f7f7;
          padding: 10px 14px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          border-radius: 6px;

          .article-img {
            margin-right: 16px;
          }
        }
      }

      .video-post {
        .article-content {
          img {
            max-width: 60%;
            display: block;
          }
        }

        .article-video {
          max-width: 60%;
          height: 100%;
          border-radius: 8px;
          overflow: hidden;
          position: relative;
          background: rgba(0, 0, 0, 0.9);
          height: 320px;
          margin: 10px 0;

          video {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }

          .videoOperationLayer {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: opacity 0.5s;

            .videoStatus {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              background: rgba(0, 0, 0, 0.5);
              -webkit-transition: background 0.2s, -webkit-transform 0.2s;
              transition: background 0.2s, -webkit-transform 0.2s;
              transition: transform 0.2s, background 0.2s;
              transition: transform 0.2s, background 0.2s,
                -webkit-transform 0.2s;
              cursor: pointer;
              text-align: center;
              line-height: 50px;

              .videoStatusIconStyle {
                font-size: 26px;
                color: #fff;
              }
            }
          }
        }
      }

      .link-content {
        background: #f7f7f7;
        border-radius: 8px;
        display: flex;
        padding: 20px;
        box-sizing: border-box;
        align-items: center;

        .article-img {
          margin-right: 12px;
          max-width: 88px;

          img {
            width: 100%;
          }
        }

        .article-name {
          font-size: 14px;
          font-weight: 700;
          color: #373737;
          margin-bottom: 9px;
        }
      }
    }

    //标签
    .tagsBox {
      .labelItem {
        height: 30px;
        min-width: 0px;
        padding: 0 10px;
        border-radius: 15px;
        background: #f2fcf8;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 8px;
        cursor: pointer;
        user-select: none;
        margin-bottom: 10px;

        .labelIcon {
          font-size: 20px;
          color: #3c6;
        }

        .labelContent {
          margin: 0 10px 0 5px;
          font-size: 12px;
          color: #1d4329;
        }
      }
    }
  }

  .operat {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .btn {
      width: 160px;
      height: 50px;
      background: #f0f0f0;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      cursor: pointer;
      color: #4d4d4d;

      &:first-child {
        margin-right: 22px;
      }
    }
  }

  /*修改滚动条样式*/
  div::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    // display: none;
  }

  div::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }

  div::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
  }

  div::-webkit-scrollbar-thumb:hover {
    background: #333;
  }

  div::-webkit-scrollbar-corner {
    background: #179a16;
  }

  /*修改滚动条样式*/

  .el-dialog__header {
    // margin-bottom: 30px;
  }

  .el-dialog__headerbtn .el-dialog__close {
    font-size: 24px;
    color: #cccccc;
  }

  .el-dialog--center,
  .el-dialog__body {
    padding: 0 !important;
    border-radius: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>
